.centered-list {
  padding-left: 0; /* Removes default padding */
  list-style-type: none; /* Ensures list markers are visible */
}

.centered-list li {
  list-style: none; /* Removes default list styling */
}

.centered-list p::before {
  content: ""; /* Removes the bullet point */
}
