/* .modalitiesbgclr {
    background-color: hsla( 132,18.51851852%,94.70588235% ,1);
    padding: 30px;

} */

/* .modalityliparent {
    display: grid;
    place-items: center;
} */
.modalitiescol1 {
    position: relative;
    display: flex;
    align-items: center;
}

.custom-top {
    position: absolute;
    right: 0;
    z-index: 1;
    /* Ensure it is on top */
    background-color: hsla(132, 18.51851852%, 94.70588235%, 1);
    padding: 20px;
    /* Adjust padding as needed */

    width: 45% !important;


}

.modalitiesimg {
    width: 100%;
    height: 555px;
}

/* .modalitiesul {
    padding: 0px 50px;
} */

.modalitiesul {
    list-style: none;
    /* Removes default bullet points */
    padding: 0px 50px;
    /* Removes left padding */
}

.modalitiesul li {
    position: relative;
    padding-left: 25px;
    /* Space for the custom bullet */
}

.modalitiesul li::before {
    content: '⦿';
    /* Custom bullet */
    color: #D4AF37;
    /* Gold color */
    font-size: 20px;
    /* Adjust size of the bullet */
    position: absolute;
    left: 0;
    top: 0;
}

.content-justify p {
    text-align: justify;
}