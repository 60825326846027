.homepage {
    background-image: url("../../assets/img/banner/homebgimg.jpg");
    /* Specify the path to your image */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 120vh;
    width: 100%;
    text-align: center;
    filter: brightness(1.1);
}

.bg-overlay {
    background-color: #2222225c;
    height: 100%;
    display: grid;
    place-items: center;
    align-items: center;
}

.homepage .container {
    height: 100vh;
    display: grid;
    place-items: center;
    text-align: center;
}

.allbanerheading {
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.5em;
    color: #ffff;
}

.midpartbanner {
    padding-top: 90px;
}

.bannerimg {
    height: 360px;
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.bannerimgcontainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bannerheading {
    color: #03492f;

    font-size: 58px;
    margin-bottom: 0px;
    word-spacing: 10px;
}

.boldText {
    font-weight: 600;
    font-size: 15px;
    margin: 0 4px;
}

.bannerheading2 {
    color: #03492f;
    /* text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 4px 4px 16px rgba(0, 0, 0, 0.2),
    6px 6px 24px rgba(0, 0, 0, 0.2), 8px 8px 32px rgba(0, 0, 0, 0.2); */
    font-size: 60px;
    word-spacing: 60px;
}

.spacebanheading {
    margin-top: 45px;
}

.bannersubheading {
    color: #fff;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.5em;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 4px 4px 16px rgba(0, 0, 0, 0.2),
        6px 6px 24px rgba(0, 0, 0, 0.2), 8px 8px 32px rgba(0, 0, 0, 0.2);
}

.padding-all {
    padding-top: 3.3vmax;
    padding-bottom: 3.3vmax;
}

/* About  */
.aboutbg {
    /* background-image: url("../../assets/img/banner/GlitterBorder2.png"); */

    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    display: grid;
    place-items: center;
    align-items: center;
}

/* end about */

/* specialties */
.title {
    font-size: 35px;
}

.specialitiesbg {
    background-image: url("../../assets/img/elements/specialitiesstrip.png");

    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 3.3vmax !important;
    padding-bottom: 3.3vmax !important;
    /* height: 100vh; */
}

.spaceblock {
    padding-top: 40px;
    padding-bottom: 40px;
}

/* end specialties */
/* About healer */
.abouthealerbg {
    background-color: #eff4f1;
}

.subheadings {
    font-size: 30px;
}

.abouthealershort {
    font-size: 22px;
    margin: 34px 0px;
    line-height: 28px;
}

.bigwraper {
    padding-top: 5vmax;
    padding-bottom: 5vmax;
}

.healerimg {
    width: 90%;
    border-radius: 50%;
}

/* .healerimgparent {
    display: grid;
    place-items: center;
} */

/* end healer */
.mainheadings {
    font-size: 50px;
    color: #522900;
}

.innerpadding-all {
    padding: 0px 0px 0px 0px;
}

.haboutusheadings {
    font-family: damion !important;
    color: #522900;
    font-size: 60px;
}

.mainheadings {
    color: #522900;
    font-size: 50px;
}

.overlapping-container {
    position: relative;
    width: 100%;
    height: auto;
}

.flowersiteimg img {
    width: 140px;
    height: 140px;
}

.flowersiteimg {
    text-align: center;
}

.overlapping-img {
    width: 100%;
    height: auto;
    position: absolute;
    transition: transform 0.3s;
}

.img1 {
    z-index: 1;
    top: 0;
    left: 0;
    width: 50%;
    transform: rotate(-5deg);
}

.img2 {
    z-index: 2;
    top: -42px;

    left: 183px;
    width: 50%;
    transform: rotate(14deg);
    height: 286px;
}

.img3 {
    width: 100%;
}

.overlapping-img:hover {
    transform: scale(1.05);
}

.textjustify {
    text-align: justify;
}

.testimonialinerpart {
    padding: 30px 30px;
    border-radius: 5px;
    background-color: #fbcbc63b;
}

.testimonialinerpart .testpara {
    padding: 20px 0px;
}

.testimonialinerpart h4 {
    font-family: prata;
    color: #522900;
}

.testimonialperson {
    display: flex;
    align-items: center;
    padding-top: 18px;
}

.testimonialperson img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

.testimonialperson p {
    margin-bottom: 0px;
    font-size: 13px;
    font-family: prata;
    color: #522900;
}

.tesmonvideoimgparent1 {
    background-image: url(.../../../../assets/img/elements/testimonialvideoimg1.jpg);
    background-position: center center;
    background-size: cover;
    height: 380px;
    border-radius: 20px 150px 20px 20px;
    position: relative;
}

.tesmonvideoimgparent2 {
    background-image: url(.../../../../assets/img/elements/testimonialvideoimg1.jpg);
    background-position: center center;
    background-size: cover;
    height: 380px;
    border-radius: 20px 20px 20px 150px;
    position: relative;
}

.tesmonvideoimgparent3 {
    background-image: url(.../../../../assets/img/elements/testimonialvideoimg1.jpg);
    background-position: center center;
    background-size: cover;
    height: 380px;
    border-radius: 5px;
    position: relative;
}

.testmonoverlay1 {
    border-radius: 20px 150px 20px 20px;
}

.aboutarrow a {
    color: #03492f;
    /* text-decoration: none; */
    font-size: 18px;
}

.aboutarrow:hover .icon_arrow {
    transform: translateX(5px);
}

.aboutarrow {
    transition: 0.5s ease all;
}

.testmonoverlay {
    z-index: 1;
    color: #fff !important;
    display: grid;
    place-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.testmonoverlay p {
    color: #fff !important;
}

.testmonoverlay2 {
    border-radius: 20px 20px 20px 150px;
}

.testmonoverlay3 {
    border-radius: 5px;
}

.videoicon {
    color: #fff;
    width: 70px;
    height: 70px;
}

.serviceimg {
    width: 100%;
    height: 300px;
    border-radius: 60px 0px 60px 0px;
}

.textleft {
    text-align: left;
}

.txtcenter {
    text-align: center;
}

.testimoniallink {
    text-decoration: none;
}

/* .buttonstyle:hover {
    
    background-color: #764979;
} */

.buttonparent {
    margin-top: auto;
}

.servicecol {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* background-color: #ffffffcf; */
    border-radius: 60px 0px 60px 0px;
    padding-bottom: 20px;
    box-shadow: 3px 3px 10px 0 rgba(57, 64, 77, 0.1);
}

.swiper-button-prev:after,
.swiper-button-next:after {
    color: #fff;
    font-size: 40px !important;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    display: none;
}

.specilitiesimges {
    width: 100%;
    height: 100%;
}

/* about us */

.footerimgdiv a {
    text-decoration: none;
    color: #522900;
    font-family: centaur;
}

/* .imgblockpspace {
  padding-top: 17px;
  padding-bottom: 17px;
} */

.footerimgdiv img {
    width: 100%;
}

/* end about us */

.banner_bg {

    display: grid;
    align-items: center;
    place-items: center;
    background-color: #eff4f1;


}

.Bolditalic {
    font-family: brandon;
    font-size: 30px;
}

.whatifcol {
    display: flex;
    flex-direction: column !important;
}

.slider_button {
    background-color: transparent;
    border: none;
}

.Navpagesheading {
    font-size: 30px !important;
}

.mobileviewicons {
    display: none;
}

.desktopviewicons {
    display: block;
}

.golddots {
    color: #D4AF37;
    font-size: 18px;
    min-width: 45px !important;
}
.itsallabtyoubg {
    background-image: url(../../assets/img/elements/testimonialbgimg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
@media screen and (max-width: 768px) {
    .homepage {
        height: 55vh;
        /* background-image: url(../../assets/img/banner/homepage-mobile-banner2.webp); */
        background-image: url(../../assets/img/banner/homebgimg.jpg);

        width: 100%;

    }

    .homepage .container {
        height: 55vh;
    }

    .bannerheading {
        font-size: 35px;
    }

    .bannerheading2 {
        font-size: 38px;
        word-spacing: 15px;
    }

    .bannersubheading {
        font-size: 12px;
    }

    .spacebanheading {
        margin-top: 0px;
    }

    .innerpadding-all {
        padding: 0px 10px 0px 10px;
    }

    .Navpagesheading {
        font-size: 24px !important;
    }

    .Navpagessubheading {
        font-size: 35px !important;
    }

    .allbanerheading {
        font-size: 16px;
    }


    .Allbutton {
        padding: 13px 15px !important;
        font-size: 12px !important;
    }

    h4 {
        font-size: 35px !important;
    }

    .bigwraper {
        padding-top: 4.5vmax;
        padding-bottom: 4.5vmax;
    }


    .title {
        font-size: 23px;
    }

    .subheadings {
        font-size: 23px;
    }

    .healerimg {
        width: 100%;
        border-radius: 50%;
    }

    .buttonparent {
        text-align: center;
    }

    .mobileviewicons {
        display: block;
    }

    .desktopviewicons {
        display: none;
    }

    .worktogetherbg {
        background-size: contain !important;

        background-position: center center !important;

        padding-left: 5px;

        padding-right: 5px;
    }
    .listitempading {
        padding-left: 8px !important;
    }

}