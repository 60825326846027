
.galryparent {
    position: relative;
    overflow: hidden;
    padding-bottom: 120%;
}

.galryparent img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.galryparent:hover img {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.galryparent:hover:after {
    width: 100%;
}

.galryparent:hover .insstripoverlay {
    opacity: 1;
    pointer-events: all;
}

.galryparent .insstripoverlay{
    opacity: 0;
    text-align: center;
    background-color: rgb(0 0 0 / 44%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 60px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    pointer-events: none;
    -webkit-transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
    color: #fff;
}
.galryparent .insstripoverlay .instainersection {
   
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }