.testimonialbg {
  background-image: url("../../assets/img/elements/testimonialbgimg.png");

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

 
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  /* .testimonialbg {
    height: 160vh;
  } */
}
