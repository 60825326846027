.Contactbanerbg {
    background-image: url(../../assets/img/banner/contactpagebanner.jpg);
    background-size: cover;
    position: center center;
    height: 100vh;
    background-repeat: no-repeat;

}

.conatctoverlay {
    background-color: #2222225c;
    display: grid;
    place-items: center;
    height: 100%;
}

.contactitalichead {
    font-style: italic;

}

.groupimg {
    width: 100%;
}

.instaimges {
    height: 250px;
    width: 100%;
}

/* .instaposts {
    height: 100%;
    width: 100%;
} */

.instastripbtn {
    font-size: 26px;
    line-height: 25px;
    padding: 12px 15px;
    border-radius: 50%;
}

.instastripparent {
    display: flex;
    gap: 20px;
}

.instalogoname {
    letter-spacing: 2px;
    font-family: Cinzel-Decorative;
    color: #352C2D;
}

.instainersection p {
    color: #fff
}

.instainersection {
    display: none;
}


.instaparent {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-bottom: 85%;
    /* 1:1 Aspect Ratio */
    margin-bottom: 1rem;
}

.instaparent img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.embed-container {
    position: absolute;
    top: -55px;
    left: 0;
    width: 100%;
    height: 356px;
}

.embed-container .instaposts iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    /* Remove any default border */
}

.instaparent:hover img {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.instaparent:hover:after {
    width: 100%;
}

.instaparent:hover .insstripoverlay {
    opacity: 1;
    pointer-events: all;
}

.instainersection {
    opacity: 0;
    display: none;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.instaparent .insstripoverlay {
    opacity: 0;
    text-align: center;
    /* background-color: #00000075; */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    -webkit-transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
    color: #fff;
    opacity: 1;
    /* Set initial opacity to 1 */
}

.instaparent:hover .insstripoverlay {
    background-color: #00000075;

}

.instaparent .insstripoverlay .instainersection {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.insstripoverlay:hover .instainersection {
    opacity: 1;
    display: block;
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);

}

.insstripoverlay:hover .instastripbtn {
    opacity: 0;
    display: none;
}

.contactusleftimg {
    height: 395px;
}

.instalogonamemid {
    color: #fff;
    font-family: Cinzel-Decorative;
}

.contactuslinks a {
    color: black;
    text-decoration: none;
}

.contactusleftdetails {
    padding-right: 48px;
}

@media screen and (max-width: 768px) {
    .Contactbanerbg {
        height: 55vh;
    }

    .contactusleftdetails {
        padding-right: 0px;
    }
    .contactusleftimg {
        height: 245px;
    }
}