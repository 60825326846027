textarea {
    width: 100%;
    min-height: 100px;
}

label {
    font-weight: 400;
    line-height: 1.8em;
    color: #000000;
}

.required {
    font-size: 15px;
    color: #ce2c30;
}

.error {
    align-items: flex-start;
    background-color: #ce2c30;
    color: #fff;
    column-gap: 10px;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    padding: 6px 10px;
    margin: 10px 0px !important;
}

.error svg {
    display: block;
    flex-shrink: 0;
    margin: auto 0;
}

