.e-cardimg {
    width: 100%;
    /* border-radius: 10px; */
    border-radius: 10px 155px 10px 10px;
}

.e-cardparent {
    /* box-shadow: rgba(122, 122, 122, 0.3) 0px 15px 30px, rgba(122, 122, 122, 0.1) 0px -3px 4px; */
    border-radius: 8px;
    border: 1px solid #f4f4f4;
    /* box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset; */
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.eservicehead {
    text-align: center;
    color: #212529;
    font-size: 16px;
    font-weight: 600;
    background: linear-gradient(90deg, #2e8f7a, #212529);
    /* Darkened green #2e8f7a */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

}

.e-cardbg {
    background-image: url(.././../assets/img/elements/e-cardbg2.jpeg);
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;

}

.ecardlistpadding {
    padding: 5px 0px !important;
    font-size: 15px;
}

.ecardexper {
    font-weight: 600;
}

.ecardicons {
    padding: 11px 15px 14px 15px;
    background-color: #03492f;
    border-radius: 50%;
    color: #fff;
}

.ecardservicesparent {
    margin-left: 1rem !important;
    margin-bottom: 1.5rem;
}

.ecardbgparent {
    background-image: url(.././../assets/img/elements/ecardborder.png);
    background-position: bottom left;
    background-size: auto;
    background-repeat: no-repeat;
    height: 100%;
}

.ecardlinks a {
    color: #03492f;
    text-decoration: none;
}



@media screen and (max-width: 768px) {
    .e-cardbg {
        background-size: contain;
        background-position: center top;
    }

    .ecardlisting {
        text-align: center;
    }

    /* .ecardiconsparent {
        display: flex;
        align-items: center;
    } */

    .ecardiconsparent p {
        margin-left: 8px !important;
    }

    .ecardservicesparent {
        margin-left: 0px !important;
        align-items: center;

    }

    .ecardlinks {
        margin-bottom: 20px;
    }

    .ecardlinks .ecardiconsparent .mobileiconname {
        display: none;
    }

    .eacardlistitempading2 {
        padding-left: 0px !important;

    }

    .mobecardbgparent {
        background-image: url(.././../assets/img/elements/ecardbordermob.png);
        background-position: top right;
        background-size: auto;
        background-repeat: no-repeat;
        
        display: block;
    }

    .ecardbgparent {
        background: border-box;
    }

    .appointmentimg {
        width: 100%;
    }
}