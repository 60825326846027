.heading-color {
  color: #084931 !important;
}
.Allbutton {
  position: relative;
  overflow: hidden;

  color: #ffffff;
  padding: 15px 30px;
  letter-spacing: 0.1em;
  font-size: 13px;
  background-color: #03492f;
  font-weight: 400;
  font-style: normal;
  transform: translate(0%, 0%);

  border-radius: 0.375rem;
  border: 2px solid transparent;
  font-family: centaur;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.2s ease, color 0.3s ease;
  cursor: pointer;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.Allbutton span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.Allbutton span:last-child {
  color: #03492f;
  display: block;
  position: absolute;
  bottom: 0;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translateY(225%) translateX(-50%);
  height: 8px;
  line-height: 13px;
  white-space: nowrap;
}

.Allbutton:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.Allbutton:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.Allbutton:hover span:last-child {
  transform: translateX(-50%) translateY(-100%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}
