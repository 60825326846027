/* .contactsectionimg{
    width: 100%;
    height: 100%;
    margin: 20px 0px;
} */
.title2 {
    font-size: 28px;
    letter-spacing: 1px;
    line-height: 1.5em;

}

.appointmentimg {
    width: 70%;
    
    border-radius: 5px;
}

.appointmentp {
    /* padding: 0px 80px 0px 0px; */
    text-align: justify;
    
}

.appointmentimgparent {
    text-align: right;
}

@media screen and (max-width: 768px) {

    .title2 {
        font-size: 23px;
    }
    .appointmentimgparent {
        text-align: center;
    }

}