.worktogetherbg {

    background-image: url(../../assets/img/banner/GlitterBorder2.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 5vmax !important;
    /* padding-bottom: 3.3vmax !important; */
}

.circlediv {
    width: 12%;
    border-radius: 50%;
    background-color: #034929;
    padding: 6px 0px;
    color: #ffff;
    margin: 0px 0px 40px 0px;
}

.wrksentence {
    font-size: 24px;

}

@media screen and (max-width: 768px) {
    .wrksentence {
        font-size: 18px;
    }

}