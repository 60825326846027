.feebg {
    background-image: url(../../assets/img/banner/feebgimg.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

.feesideimg {
    width: 100%;
    height: 500px;
}

.feetoppara p {
    font-style: italic;
}

.Goodfaithbg {
    background-image: url(../../assets/img/elements/testimonialbgimg.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

}

.thoughtbg {
    background-color: hsla(132, 18.51851852%, 94.70588235%, 1);


}

.thoughtpara {
    font-family: brandon;
    color: #000000;
    font-weight: 600;
    text-align: center;
    font-size: 30px;
    line-height: 28px;
}

.thoughtname {
    text-align: right;
}

.thoughtparent {
    line-height: 2.8em;
    text-align: center;
    display: grid;
    place-content: center;

}

.packagesparent a {
    text-transform: capitalize;
    color: #03492f;
    font-size: 19px;
}

.packagesessionbasic {
    font-size: 18px;
    margin-left: 15px;
}

.packagesession {
    font-size: 18px;
    margin-left: 30px;
}

.Packageheader {
    font-family: none !important;
}

.packageitem {
    border: none !important;
}

.packageitem .accordion-button:not(.collapsed) {
    background-color: transparent !important;
}

.packageitem .accordion-button {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
}

.packageitem .accordion-body {
    background-color: hsla(132, 18.51851852%, 94.70588235%, 1);

}

.packageitem .accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
}

.coursehighlight {
    font-weight: 600;
    color: #03492f;
}

@media screen and (max-width: 768px) {
    .feebg {
        height: 55vh;
    }

    .conatctoverlay {
        height: 55vh;
    }

}

/* .packagesessionbasic {
    margin-left: 64px;
}
.packagesession {
    margin-left: 30px;
}
.packageprice {
    margin-left: 5px;
} */