/* .chakrabalancingbg {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  
} */
.chakrabalancingbg {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url(../../assets/img/banner/chakrabalancingbgvideobanner.gif);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    /* Full height for the video */
    overflow: hidden;
    /* To ensure content doesn't overflow */
}

.video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the video covers the entire container */
    z-index: -1;
    /* Push video behind content */
}

.content-overlay {
    position: relative;
    z-index: 1;

    color: white;
    text-align: center;
    padding: 100px 20px;

    background-color: rgba(0, 0, 0, 0.3);

}

.chkrasnenergyimg {
    border-radius: 155px 10px 10px 10px;
}

@media screen and (max-width: 768px) {
    .chakrabalancingbg {
        height: 55vh;
    }
}