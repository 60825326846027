.subheadright {
    text-align: right;
}

.subheadleft {
    text-align: left;
}

.Gallerypageimg {
    width: 100%;
    height: 350px;
}

.subheaderbg {
    background-image: linear-gradient(to bottom, rgba(234, 233, 229, 0.5), rgba(234, 233, 229, 0.5)), url('../../assets/img/gallery/Gallerybgimg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 350px;
    display: grid;
    place-items: center;
    /* background-color: ; */

}

.subheaderlinkhead a {
    color: #fff;
    text-decoration: none;
}

.subbanerheading {
    color: #522900;
    font-weight: 700;
    font-size: 80px;
}

.glryvideo-item {
    width: 100%;
    height: 315px;
    background-color: #000;
    /* Set the desired height here */
}

