.footerbgclr {
    background-color: #ffffff;

}

.addressul p a {
    color: #000;
    text-decoration: none;

}

.nav-links {
    list-style: none;
}

.nav-links a {
    color: #034929;
    font-size: 16px;
    text-decoration: none;
}

/* footerlink hover */

.nav-links a {
    position: relative;
    text-decoration: none;
    color: inherit;
}

.nav-links a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    /* Adjust the position as needed */
    width: 0;
    height: 2px;
    /* Adjust the height as needed */
    background-color: #000;
    /* Adjust the color as needed */
    transition: width 0.3s;
}

.nav-links a:hover::after,
.nav-links a.active::after {
    width: 100%;
}

/* end footerlinks hover */

.footerlogoimgcontainer img {
    width: 80px;
    height: 80px;
    text-align: center;
}

.addressul {
    list-style: none;
}

.addressul b {
    font-size: 16px;
}

.social-links {
    display: flex;
    gap: 20px;

}


.social-links a {
    color: #034929;
    gap: 20px;

}


.social-links .ftrsocial {
    color: #000000;
    font-size: 18px;
}

.ftrnosize {
    font-size: 18px;
}

.ftrnosize a {
    font-size: 18px;
    color: #fff;
    text-decoration: none;
}

.Footerdownbg {
    background-color: #034929;
    color: #ffff;
}

.Footerdownbg p {

    color: #ffff;
}

.copyright p {
    font-size: 16px;
}

.copyright a {
    font-size: 16px;
    color: #000000;
}