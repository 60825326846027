.Anxitypagebg {
  background-image: url(../../assets/img/banner/anxiety.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
  background-repeat: no-repeat;
}
.anxietysignbg {
  background-color: hsla(132, 18.51851852%, 94.70588235%, 1);
}
.Anxietysignimg {
  width: 100%;
}
.wrktogthrhlpyoubg {
  background-color: #eff4f1;
}
.anxiety-parallax {
  background-image: url(../../assets/img/banner/parallax.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@media screen and (max-width: 600px) {
  .Anxitypagebg {
    height: 55vh;
  }
  .anxiety-parallax{
    height: 80vh;
    display: none;
  }
 
}
