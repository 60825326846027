.litleaboutmebg {
    background-image: url(.././../assets/img/elements/litleaboutmebg.png);
    background-position:bottom right;
    background-size: auto;
    background-repeat: no-repeat;
    height: 100%;
}
.litleaboutmebg1 {
    background-image: url(.././../assets/img/elements/litleaboutmebg2.png);
    background-position:bottom right;
    background-size: auto;
    background-repeat: no-repeat;
    height: 100%;
}