.styles-module_whatsappButton__tVits {
    bottom: 6rem;
    right: 1rem;
    width: 55px;
    height: 55px;
}
.scroll-top {

    bottom: 1rem;
    right: 1rem;
  
  
}

.scroll-to-top {
    background-color: white;
 
    position: fixed;
    z-index: 2;
    cursor: pointer;
    border-radius: 7px;
    width: 50px;
    height: 50px;
    box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
    border: none;
    background-color: #034929;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}
.scroll-top svg {
   color:#fff;
  
}