body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", sans-serif,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #EAE9E5 !important; */
}

h2,
h4 {
  font-family: brandon !important;
}
h5,
h6,
h3 {
  font-family: centaur !important;
  color: #162544;
  text-transform: uppercase;
}
h4 {
  letter-spacing: 1.5px;
  font-size: 50px !important;
  line-height: 42px !important;
  color: #000000;
}

p {
  margin: 17px 0px !important;
  /* font-family: monestrate; */
}

p {
  font-size: 15px;
  color: #000000;
  line-height: 1.8em;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@font-face {
  font-family: brandon;
  src: url(./assets/fonts/Great_Vibes/GreatVibes-Regular.ttf);
}


@font-face {
  font-family: monestrate;
  src: url(./assets/fonts/Montserrat/static/Montserrat-Regular.ttf);
}
@font-face {
  font-family: centaur;
  src: url(./assets/fonts/Centaur-Font/CENTAUR.TTF);
}
@font-face {
  font-family: monestratebold;
  src: url(./assets/fonts/Montserrat/static/Montserrat-Bold.ttf);
}

::-webkit-scrollbar {
  width: 0px;
}
