.modal__background {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  overflow-y: scroll;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__content {
  min-width: 500px;
  max-height: 100%;
  border-radius: 20px;
  position: relative;
  background-color: white;
  padding: 25px;
  background-position: 50% 50%, 0 0;
  background-size: 3.125rem, auto;
  overflow-y: scroll;
}
.modal__content::-webkit-scrollbar {
  width: 0;
}
@media screen and (max-width: 600px) {
  .modal__content {
    min-width: 400px;
  }
}

.modal__content__close {
  /* position: absolute; */
  bottom: 0px;
  /* right: 20px; */
  background: none;
  border: none;
  color: black;
  font-weight: 900;
  font-size: 24px;
}

.modal__content::-webkit-scrollbar {
  width: 5px;
}

.modal__content::-webkit-scrollbar-track {
  border-radius: 10px;
}
