.testimonial-bg {
  background-image: url("../../assets/img/elements/testimonialbgimg.png");

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  /* height: 90vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .testimonial-bg {
    /* height: 70vh; */
  }
}
