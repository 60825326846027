.testimonialpagespace {
  margin-bottom: 80px;
}

.testimonialpagename {
  color: #522900;
  font-weight: 600;
}

.testimonialpagebg {
  background-image: url(../../assets/img/banner/testimonialpagebg.jpg);
  position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.testimonsubanerheadimg {
  font-family: centaur;
  font-size: 18px;
  letter-spacing: 0.75px;
  line-height: 28px;
  text-transform: uppercase;
}

.testimpagehead {
  font-style: italic;
  color: #000000;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.5em;
}

.testimonstars {
  font-size: 20px;
}

.username {
  font-weight: 600;
  font-size: 17px;
}

.overlaybanner {
  background-color: hsl(0deg 0% 100% / 66%);
  height: 100vh;
  display: grid;
  place-items: center;
  align-items: center;
  padding: 0px;
  opacity: 1;
}


@media screen and (max-width: 768px) {
  .testimonialpagebg {
    height: 55vh;
  }
  .overlaybanner {
    height: 55vh;
  }
}