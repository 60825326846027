.thnkyoubg {
    background-image: url("../../assets/img/banner/thankyoubgimg.jpg");
    /* Specify the path to your image */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 120vh;
    width: 100%;
    text-align: center;
    filter: brightness(1.1);
}

.thankyouinner {
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    text-align: center;
    background-image: url(../../assets/img/elements/ecardborder.png);
    background-position: bottom right;
    background-size: auto;
background-repeat: no-repeat;
}
.thnkmailicon {
    color: #03492f;
    font-size: 35px;
    margin: 14px 0px;
}
@media screen and (max-width: 768px) {
    .thnkyoubg {
        height: 55vh;
    }
}