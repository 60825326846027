.Chronicbgsection {
  background-image: url(../../assets/img/banner/chronic-illness.jpg);
  background-size: cover;
  background-position: center center;
  height: 100vh;
  background-repeat: no-repeat;
}
.Chronicbg-section {
  background-image: url(../../assets/img/banner/chronic-healerBg.jpg);
  background-size: cover;
  background-position: center center;
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@media screen and (max-width: 600px) {
  .Chronicbg-section {
    height: 80vh;
    display: none;
  }
  .Chronicbgsection {
    height: 55vh;
  }
}
