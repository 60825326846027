.anukulkarnimg {
  height: 90px;
  width: 90px;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
}

.navlinkcontent li {
  margin-bottom: 10px;
}

.anukulkarnimg-container {
  display: grid;

  /* place-items: center; */
}

.navlinkcontent .nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navlinkcontent .nav-link {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 1.7px 0px;

  position: relative;
  /* display: inline-block; */

  text-decoration: none;
  color: #034929;
  margin-left: 1.8vw;
  font-size: 17px;
}

.navlinkcontent .nav-link::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #034929;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.navlinkcontent .nav-link:hover::after {
  width: 100%;
}

.navlinkcontent .nav-link:hover {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 1.7px 0px;

  text-decoration: none;

  color: #034929;
  margin-left: 1.8vw;
  font-size: 17px;
}

/* .navlinkcontent .specialitiesmenu .nav-link:hover {
  color: #fff;

} */

.navlinkcontent .nav-link.active::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navlinkcontent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
}

.headerbtnparent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbg {
  background-color: #ffffff;
  position: fixed;
  z-index: 99;
}

.logoimgcontainer {
  margin: -4% 0% -26% 0%;
  padding: 0% 0% 7% 0%;
  background-image: url("../../assets/img/logo/logo1303.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 70% auto;
  text-align: center;
}

.logoimgcontainermain {
  z-index: 1;
}

.instagram {
  background: linear-gradient(45deg,
      #833ab4,
      #fd1d1d,
      #f56040,
      #ffdc80) !important;
  background-size: 400% 400%;

  -webkit-text-fill-color: transparent;
}

.facebook {
  color: #1877f2 !important;
}

.youtube-red {
  color: #ff0000 !important;
}

.nav-column-right a {
  color: black !important;
}

/* .navlinkcontent {
  display: flex;
  gap: 20px;
} */
.dropdownspecility {
  position: relative;
}

.specialitiesmenu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
  z-index: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  min-width: 200px;
  left: -43px;
}

.specialitiesmenu li {
  width: 100%;
  padding: 8px 0px;
}

.specialitiesmenu li a {
  display: block !important;
  padding: 0px 8px !important;
  color: #000;
  text-decoration: none;
  text-align: right;
  margin-left: 0px !important;
  text-align: center;
}

.specialitiesmenu li a:hover {
  /* background-color: #03492f; */
  color: #03492f;
}

.dropdownspecility:hover .specialitiesmenu {
  display: block;
}

.navlinkcontent a {
  text-decoration: none;
  color: #000;
  padding: 10px;
}

.navlinkcontent a:hover {
  color: #007bff;
}

@media screen and (max-width: 600px) {
  .anukulkarnimg {
    width: 60px;
    height: 60px;
  }

  .customPopoverClass .MuiPaper-root {
    top: 379px !important;
    left: 112px !important;
  }
}