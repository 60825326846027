.testimonial_bg {
  background-image: url("../../assets/img/elements/testimonialbgimg.png");

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 5vmax !important;

  
  display: grid;
  place-items: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
 
}
