.faqacitem {
    margin-bottom: 20px;
    border-radius: 35px 0 35px 0 !important;
    padding: 0px !important;
    /* border: 1px solid #522900 !important; */
    background-color: #ffffffcf !important;

}

.faqacitem.accordion-item:not(:first-of-type) {
    border-top: 1px solid #dee2e6 !important;
}

.faqheader {
    background-color: #ffffffcf;
    border-radius: 35px;
    font-family: -apple-system, BlinkMacSystemFont, "Poppins", sans-serif,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif !important;

}

.faqheader .accordion-button {
    border: none;
    padding: 26px;
    border-radius: 35px 0 35px 0 !important;
    box-shadow: none;
    color: #03492f;
    /* background-color:#ffffffcf !important; */
}

.faqheader .accordion-button:focus {
    outline: none;

    box-shadow: none;

    border: none;

}

.faqheader .accordion-button:not(.collapsed) {
    background-color: #03492f;
    box-shadow: none;
    border: none;
    color: #fff;

}

.faqheader .accordion-button.collapsed {
    border: none;


}

.faqheader .accordion-button.collapsed::after {
    color: #fff;


}

.faqimg {
    width: 100%;

    border-radius: 5px;
}