.aboutpageparent {
    padding: 130px 0px;
}

/* .aboutpagehr {
    color: hsla( 0,0%,0% ,1) !important;
    font-weight: 600 !important;
    height: 5px !important;
} */
.aboutmeparent {

    border-bottom: 1px solid #000000;
}

.Specialitieslist {
    list-style: none;
    /* Removes default bullet points */
    padding-left: 0;
    /* Removes left padding */
}

.Specialitieslist li {
    position: relative;
    padding-left: 25px;
    /* Space for the custom bullet */
}

.Specialitieslist li::before {
    content: '⦿';
    /* Custom bullet */
    color: #D4AF37;
    /* Gold color */
    font-size: 20px;
    /* Adjust size of the bullet */
    position: absolute;
    left: 0;
    top: 0;
}