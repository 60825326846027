.Depressionpagebg {
  background-image: url(../../assets/img/banner/depression.jpg);
  background-size: cover;
  background-position: center center;
  height: 100vh;
  background-repeat: no-repeat;
}

.Depsbbgsection {
  background-image: url(../../assets/img/banner/depression-parallax.jpg);
  background-size: cover;
  position: center center;
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@media screen and (max-width: 600px) {
  .Depressionpagebg {
    height: 55vh;
    /* display: none; */
  }

  .Depsbbgsection {
    display: none;
  }
}