.chkracoursepricebg {
/* box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; */
background-image: url(../../assets/img/banner/GlitterBorder2.png);
background-position:center center ;
background-size: contain;
background-repeat: no-repeat;
height: 100%;
}
.coursepriceimg {
    width: 100%;
    height: 515px;
    object-fit: cover;
    border-radius: 10px 155px 10px 10px;
}
.chakrainnerbg {
    background-color: #fff;
    border: 1px solid #eee;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    padding: 15px 15px;
}
.chkrabalancingbg2video {
    width: 100%;
    height: 515px;
    object-fit: cover;
    border-radius: 10px 155px 10px 10px;
}

@media screen and (max-width: 768px) {
    .coursepriceimg {
        height: 358px;
    }
}