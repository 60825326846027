.spiritualityBg {
  background-image: url(../../assets/img/banner/spritualityblockages.jpg);
  background-size: cover;
  background-position: center center;
  height: 130vh;
  background-repeat: no-repeat;
}

.sprbgsection {
  background-image: url(../../assets/img/banner/sprbgsection.jpg);
  background-size: cover;
  position: center center;
  height: 100vh;
  background-repeat: no-repeat;
}

.spiritbg {
  background-image: url("https://img.freepik.com/free-photo/wellness-practices-self-care-world-health-day_23-2151256737.jpg?ga=GA1.1.1909195037.1708060889&semt=ais_hybrid");
  background-size: cover;
  background-position: center;
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.overlay_bg {
  background-color: #00000027;
  height: 100%;
  display: grid;
  align-items: flex-start;
  place-items: center;
}

@media screen and (max-width: 768px) {
  .spiritualityBg {
    height: 55vh;
  }
}